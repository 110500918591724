<template>
	<div>
		<div class="ft20 cl-black cl-main ftw500">积分记录</div>

		<div class="mt20">
			<div class="form-search-box ">
				<a-form layout="inline">
					<!-- <a-form-item label="会员昵称">
						<a-input v-model="search.nick_name" placeholder="请输入会员昵称"></a-input>
					</a-form-item> -->
					<a-form-item label="会员手机号">
						<a-input v-model="search.mobile" placeholder="请输入会员手机号"></a-input>
					</a-form-item>
					<a-form-item label="操作日期">
						<a-range-picker @change="changeOperateTime" v-model="search.operate_time" valueFormat="YYYY-MM-DD" />
					</a-form-item>
					<a-form-item>
						<a-button @click="searchAct" type="primary">查询</a-button>
						<a-button @click="cancelAct" class="ml10">取消</a-button>
					</a-form-item>
					<a-form-item>
						<a-button class="ml10">导出</a-button>
					</a-form-item>
				</a-form>

			</div>
		</div>
		<div class="mt20">
			<div class="wxb-table-white">
				<a-table rowKey="id" :columns="columns" :pagination="pagination" @change="handleTableChange"
					:data-source="datas" :loading="loading">
					<div class="flex alcenter center" slot="member_id" slot-scope="record">
						{{ record.member != null ? record.member.member_id : '-' }}
					</div>

					<!-- <div  slot="member" slot-scope="member,record">
						<div v-if="member != null" class="flex alcenter center">
							<img v-if="member.face!=null" :src="member.face" class="member-money-recharge-face" />
							<img v-else src="../../assets/image/woyaoyou_head_defult@2x.png" class="member-money-recharge-face" />
							<div class="ml5" style="text-align: left;">
								<div>{{member.nick_name}}</div>
								<div class="mt2">{{member.mobile}}</div>
							</div>
						</div>
						<div v-else>-</div>
					</div> -->

					<!-- <div class="flex alcenter center" slot="mobile" slot-scope="record">
						{{record.member != null ? record.member.mobile : '-'}}
					</div> -->

					<!-- <div class="text-center" slot="integral" slot-scope="record">
						{{record.type==1 ? '+' : '-'}} {{record.integral}}
					</div>
					
					<div class="text-center" slot="remarks" slot-scope="remarks,record">
						{{remarks ? remarks : '-'}}
					</div>
					<div class="text-center" slot="mendian" slot-scope="mendian,record">
						{{mendian ? mendian.name : '-'}}
					</div> -->
					<div class="text-center" slot="operator" slot-scope="record">
						<div v-if="record.operate_name && record.operate_mobile">
							<div class="ft14 cl-info">{{ record.operate_name }}</div>
							<div class="ft12 cl-notice">({{ record.operate_mobile }})</div>
						</div>
						<div v-else>-</div>
					</div>
				</a-table>
			</div>
		</div>
	</div>
</template>

<script>
import moment from 'moment'
import { listMixin } from '../../common/mixin/list.js';
export default {
	mixins: [listMixin],
	data() {
		return {
			loading: false,
			pagination: {
				current: 1,
				pageSize: 10, //每页中显示10条数据
				total: 0,
			},
			search: {
				// nick_name: '',
				mobile: "",
				operate_time: ['', ''],
			},
			columns: [
				// ,
				{ title: '数据id', dataIndex: 'id', align: 'center' },
				{ title: '手机号', dataIndex: 'mobile', align: 'center' },
				{ title: '所属门店名称', dataIndex: 'mendian_name', align: 'center' },
				{ title: '内容', dataIndex: 'content', align: 'center' },
				{
					title: '类型',
					dataIndex: 'type',
					key: 'type',
					customRender: (text, row, index) => {
						return text == 1 ? '充值积分' : '消费积分'
					}
				},
				{
					title: '方向',
					dataIndex: 'direction',
					key: 'direction',
					customRender: (text, row, index) => {
						return text == 1 ? '增加' : '减少'
					}
				},
				{ title: '数量', dataIndex: 'num', align: 'center', scopedSlots: { customRender: 'num' } },
				{
					title: '创建时间',
					dataIndex: 'add_time',
					key: 'add_time',
					scopedSlots: { customRender: 'add_time' },
					customRender: (text, row, index) => {
						return moment(text * 1000).format('YYYY-MM-DD HH:mm:ss')
					}
				},

			],
			datas: [],
		}
	},
	methods: {
		getLists() {
			if (this.loading == true) return;
			this.loading = true;
			this.$http.api('admin/getMemberIntegralLogsList', {
				// nick_name:this.search.nick_name,
				mobile: this.search.mobile,
				operate_time: this.search.operate_time,
				limit: this.pagination.pageSize,
				page: this.pagination.current,
			}).then(res => {
				this.pagination.total = res.count;
				this.datas = res.res;
				this.loading = false;
			}).catch(res => {
				console.log(res);
				this.loading = false;
			})
		},

		handleTableChange(pagination, filters, sorter) {
			this.pagination.current = pagination.current;
			this.getLists();
		},

		changeOperateTime(value) {
			console.log(value, 'timevalue');
			this.search.operate_time = value;
		},

		searchCancel() {
			this.search = {
				nick_name: '',
				mobile: "",
				operate_time: ['', ''],
			};
		},
		searchAct() {
			this.getLists();
		},
		cancelAct() {
			this.searchCancel();
			this.searchAct();
		}
	}
}
</script>

<style>
.member-integral-face {
	width: 44px;
	height: 44px;
	border-radius: 44px;
	box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.04);
	border: 2px solid #FFFFFF;
}

.member-integral-action {
	width: 32px;
	height: 32px;
	border-radius: 32px;
	background: #FFFFFF;
	border: 1px solid #EBEDF5;
	text-align: center;
	line-height: 32px;
}

.member-money-recharge-face {
	width: 44px;
	height: 44px;
	border-radius: 44px;
	box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.04);
	border: 2px solid #FFFFFF;
}
</style>
